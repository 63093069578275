import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box, IconButton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LibraryBooks as LibraryBooksIcon,
  Assignment as AssignmentIcon,
  School as SchoolIcon,
  Timeline as TimelineIcon,
  Web as WebIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import NavBar from '../NavBar';
import config from '../../config/app';

const api = config.version;
const app_name = config.appName;
const drawerWidth = 240;
const collapsedDrawerWidth = 70;

const Root = styled('div')(() => ({
  display: 'flex',
  width: '100%', // Full width
  overflowX: 'hidden', // Prevent horizontal scrolling
}));

const DrawerContainer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})(({ theme, isCollapsed }) => ({
  width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
    boxSizing: 'border-box',
    height: '100vh', // Full height
    overflow: 'hidden', // Prevent scrollbar
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.standard,
    }),
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 64px)', // Adjust for toolbar height
  overflow: 'hidden', // Scroll only the content
}));

const Content = styled('main')(({ theme, isCollapsed }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create(['margin'], {
    duration: theme.transitions.duration.standard,
  }),
  //marginLeft: isCollapsed ? 0 : collapsedDrawerWidth,// : drawerWidth, // Dynamic margin based on sidebar width
  [theme.breakpoints.down('sm')]: {
    marginLeft: collapsedDrawerWidth, // Default to collapsed for smaller screens
    padding: theme.spacing(1),
  },
  minWidth: 0, // Prevent overflow caused by children
  boxSizing: 'border-box', // Ensure padding and borders don't add width
}));

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleDrawer = () => {
    setIsCollapsed((prev) => !prev);
  };

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: `${api}/dashboard` },
    { text: 'Students', icon: <SchoolIcon />, path: `${api}/students` },
    { text: 'Lesson Plans', icon: <AssignmentIcon />, path: `${api}/lessons` },
    { text: 'Resources', icon: <AssignmentIcon />, path: `${api}/resources` },
    { text: 'Weekly Plans', icon: <AssignmentIcon />, path: `${api}/weekly-plans` },
    { text: 'Journal', icon: <LibraryBooksIcon />, path: `${api}/journal` },
    { text: 'Webs', icon: <WebIcon />, path: `${api}/webs` },
    { text: 'Analytics', icon: <TimelineIcon />, path: `${api}/analytics` },
    { text: 'Settings', icon: <SettingsIcon />, path: `${api}/settings` },
  ];

  return (
    <Root>
      <CssBaseline />
      <NavBar title={app_name} />
      <DrawerContainer variant="permanent" isCollapsed={isCollapsed}>
        <br/><br/><br/>
        <DrawerContent>
          <Toolbar sx={{ justifyContent: isCollapsed ? 'center' : 'space-between', px: 1 }}>
            {!isCollapsed && (
              <Box sx={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
                Dashboard
              </Box>
            )}
            <IconButton onClick={toggleDrawer}>
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Toolbar>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                component="a"
                href={item.path}
                sx={{
                  backgroundColor: location.pathname === item.path ? 'primary.main' : 'inherit',
                  color: location.pathname === item.path ? '#aaa' : 'inherit',
                  borderRadius: '8px',
                  mx: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === item.path ? '#aaa' : 'inherit',
                    justifyContent: isCollapsed ? 'center' : 'flex-start',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary={item.text} />}
              </ListItem>
            ))}
          </List>
        </DrawerContent>
      </DrawerContainer>
      <Content>
        <Toolbar />
        <Outlet />
      </Content>
    </Root>
  );
};

export default DashboardLayout;
