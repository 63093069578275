import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';

const HeroSection = () => {
  
  const openWhitePaper = () => {
    window.open('/Edukentia_White_Paper.pdf', '_blank');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url("./images/teachercircle.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
          zIndex: 1,
        },
      }}
    >
      <Container sx={{ zIndex: 2 }}>
        <Typography variant="h2" component="h1" gutterBottom>
        Empowered Learning For Everyone
        </Typography>
        <Typography variant="h5" component="h5" gutterBottom>
        Transform your classroom experience, save time, and enhance student outcomes.</Typography>
        {/*<Button
          variant="contained"
          color="warning"
          sx={{ mt: 3 }}
          onClick={openWhitePaper}
        >
          Find Out More
        </Button>*/}
      </Container>
    </Box>
  );
};

export default HeroSection;

