import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
  Box, 
  Card, 
  CardActions, 
  CardContent, 
  Typography, 
  IconButton, 
  Chip, 
  Stack, 
  Button, 
  TextField, 
  Popper, 
  Paper, 
  List, 
  ListItem, 
  ListItemText 
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import { fetchWebRequest } from "../../actions/webActions";

const WebList = ({ entityName, webs, fetchWebRequest }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredWebs, setFilteredWebs] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    fetchWebRequest(); // Fetch webs on component mount
  }, [fetchWebRequest]);

  useEffect(() => {
    if (searchTerm) {
      const results = webs.filter((web) =>
        web.class_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        web.subjects?.some((subject) =>
          subject.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredWebs(results);
      setIsOverlayVisible(true);
    } else {
      setFilteredWebs([]);
      setIsOverlayVisible(false);
    }
  }, [searchTerm, webs]);

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchResultClick = (web) => {
    console.log("Selected web:", web);
    setSearchTerm("");
    setIsOverlayVisible(false);
  };

  return (
    <Box sx={{ mx: "auto", minWidth: 275, p: 3 }}>
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        {entityName}
      </Typography>

      {/* Search Field */}
      <Box sx={{ mb: 2, position: "relative" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for webs..."
          value={searchTerm}
          onChange={handleSearchInput}
          sx={{ backgroundColor: "white" }}
        />
        {isOverlayVisible && (
          <Popper open={isOverlayVisible} style={{ zIndex: 10, width: "100%" }}>
            <Paper elevation={3}>
              <List>
                {filteredWebs.length > 0 ? (
                  filteredWebs.map((web) => (
                    <ListItem
                      button
                      key={web.id}
                      onClick={() => handleSearchResultClick(web)}
                    >
                      <ListItemText
                        primary={`${web.class_name || "Unknown Class"} (${web.age_group || "Age not specified"})`}
                        secondary={`${web.subjects?.join(", ") || "No subjects available"}`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No results found." />
                  </ListItem>
                )}
              </List>
            </Paper>
          </Popper>
        )}
      </Box>

      {/* Web List */}
      {webs.length > 0 ? (
        webs.map((web) => (
          <Card sx={{ m: 2 }} variant="outlined" key={web.id}>
            <CardContent>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <IconButton aria-label="download">
                  <DownloadIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </Stack>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {`${web.class_name || "Unknown Class"} (${web.age_group || "Age not specified"})`}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {`${web.duration || "N/A"} Weeks`}
              </Typography>
              <Typography variant="body2">Subjects:</Typography>
              <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                {web.subjects && web.subjects.length > 0 ? (
                  web.subjects.map((subject, index) => <Chip key={index} label={subject} />)
                ) : (
                  <Chip label="No subjects available" />
                )}
              </Stack>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.secondary">
                {web.term || "Term Info Not Available"}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip label={`${web.related_webs || 0} Related Thematic Webs`} variant="outlined" />
                <Button variant="outlined" color="primary" size="small">
                  View
                </Button>
              </Stack>
            </CardActions>
          </Card>
        ))
      ) : (
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            No {entityName} found.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ mx: 1 }}
            onClick={() => {
              console.log("Redirect to create new web");
            }}
          >
            Create New Web
          </Button>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  entityName: "Web",
  webs: state.web || [],
});

const mapDispatchToProps = {
  fetchWebRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebList);
