import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TimelineContainer from "../timeline/TimelineContainer";
//import dayjs from "dayjs";

const Journal = () => {
  const [entries, setEntries] = useState([
    {
      date: "",
      subject: "Mathematics",
      topic: "Fractions",
      lessonTitle: "Naming fractions",
      summary: "Introduction to fractions",
      icon: "lesson",
    },
    {
      date: "",
      subject: "Language Arts",
      topic: "Grammar",
      lessonTitle: "Nouns",
      summary: "Understanding nouns and their usage",
      icon: "lesson",
    },
  ]);

  const [inputText, setInputText] = useState("");

  const parseInput = (input) => {
    const fields = input.split("#").filter(Boolean);
    const result = { date:"", icon: "lesson" };

    fields.forEach((field) => {
      const [key, ...value] = field.split(":");
      if (key && value) {
        result[key.trim()] = value.join(":").trim();
      }
    });

    return result;
  };

  const handleAddEntry = () => {
    if (inputText.trim()) {
      const newEntry = parseInput(inputText);
      if (newEntry.subject && newEntry.topic) {
        setEntries([...entries, newEntry]);
        setInputText(""); // Clear input
      } else {
        alert("Please include both #subject and #topic in your input.");
      }
    } else {
      alert("Input cannot be empty.");
    }
  };

  return (
    <Box sx={{ mx: "auto", minWidth: 275, p: 3 }}>
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        Journal
      </Typography>

      {/* Timeline Display */}
      <TimelineContainer entries={entries} />

      {/* Add Entry Form */}
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "stretch",
        }}
      >
        <Typography variant="h6">Add New Entry</Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Enter entry details using #field: value format (e.g., #topic: Fractions #subject: Math)"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddEntry}
          sx={{
            alignSelf: "flex-end", // Aligns the button to the left
            px: 2, // Padding on X-axis (horizontal)
            py: 1, // Padding on Y-axis (vertical)
            minWidth: "auto", // Ensures button size fits content
          }}
        >
          Add Entry
        </Button>
      </Box>
    </Box>
  );
};

export default Journal;
