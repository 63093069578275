import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const LessonPlansPage = () => {
  const [lessonPlans, setLessonPlans] = useState([]);
  const [selectedLessonPlan, setSelectedLessonPlan] = useState(null);

  useEffect(() => {
    // Mock fetch lesson plans (replace with API call)
    const mockLessonPlans = [
      { id: 1, title: "Fractions in Mathematics", grade: "Grade 3", date: "2023-10-15" },
      { id: 2, title: "Introduction to Grammar", grade: "Grade 5", date: "2023-10-20" },
      { id: 3, title: "Basics of Geometry", grade: "Grade 6", date: "2023-11-01" },
    ];
    setLessonPlans(mockLessonPlans);
    setSelectedLessonPlan(mockLessonPlans[0]); // Set the first lesson plan as selected
  }, []);

  const handleSelectLessonPlan = (lessonPlan) => {
    setSelectedLessonPlan(lessonPlan);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, p: 3 }}>
      {/* Left Side: List of Lesson Plans */}
      <Box
        sx={{
          flex: { md: 1 },
          bgcolor: "background.paper",
          borderRadius: 2,
          overflowY: "auto",
          height: { xs: "auto", md: "100vh" },
          p: 2,
        }}
      >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            color="primary"
            onClick={() => console.log("Redirect to Create Lesson Plan")}
            sx={{ ml:15 }}
          >
            Create Lesson Plan
          </Button>   
          
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
          <Typography variant="h5">Lesson Plans</Typography>

        </Stack>
        <Divider />
        <List>
          {lessonPlans.map((lessonPlan) => (
            <ListItem
              key={lessonPlan.id}
              button
              onClick={() => handleSelectLessonPlan(lessonPlan)}
              selected={selectedLessonPlan?.id === lessonPlan.id}
              sx={{ borderBottom: "1px solid", borderColor: "divider" }}
            >
              <ListItemText
                primary={lessonPlan.title}
                secondary={`${lessonPlan.grade} - ${lessonPlan.date}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Side: Display Selected Lesson Plan */}
      <Box
        sx={{
          flex: { md: 2 },
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 3,
          height: { xs: "auto", md: "100vh" },
          overflowY: "auto",
        }}
      >
        {selectedLessonPlan ? (
          <Card>
            <CardContent>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {selectedLessonPlan.title}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Grade: {selectedLessonPlan.grade}
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                Scheduled Date: {selectedLessonPlan.date}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Objectives:</strong> <br />
                - Cognitive: Understand key concepts of {selectedLessonPlan.title}. <br />
                - Psychomotor: Apply hands-on exercises related to {selectedLessonPlan.title}. <br />
                - Affective: Engage and appreciate the importance of the topic.
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">
                <strong>Activities:</strong>
                <ul>
                  <li>Activity 1: Group discussion on {selectedLessonPlan.title}</li>
                  <li>Activity 2: Practice worksheet</li>
                  <li>Activity 3: Class presentation</li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography variant="h6" align="center">
            Please select a lesson plan to view details.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LessonPlansPage;
