import React from "react";
import { Box, Card, CardContent, Typography, Grid, Divider } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, ArcElement, LineElement } from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";

// Register required Chart.js modules
ChartJS.register(CategoryScale, LinearScale,ArcElement, PointElement ,BarElement, LineElement,Title, Tooltip, Legend);

const sampleData = {
  studentPerformance: {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Math",
        data: [85, 90, 80, 75],
        backgroundColor: "#42A5F5",
      },
      {
        label: "Science",
        data: [78, 82, 88, 85],
        backgroundColor: "#66BB6A",
      },
    ],
  },
  resourceUsage: {
    labels: ["Videos", "Quizzes", "Assignments", "Others"],
    datasets: [
      {
        label: "Usage",
        data: [60, 30, 25, 15],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
      },
    ],
  },
  attendanceTrend: {
    labels: ["January", "February", "March", "April"],
    datasets: [
      {
        label: "Attendance Rate",
        data: [90, 85, 95, 92],
        fill: true,
        backgroundColor: "rgba(66, 165, 245, 0.2)",
        borderColor: "#42A5F5",
        tension: 0.4,
      },
    ],
  },
};

const Analytics = () => {
  return (
    <Box sx={{ p: 3 }}>
      {/* Page Title */}
      <Typography variant="h4" textAlign="center" sx={{ mb: 3 }}>
        Analytics Dashboard
      </Typography>

      {/* Analytics Cards */}
      <Grid container spacing={3}>
        {/* Performance Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Student Performance (Weekly)
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Bar data={sampleData.studentPerformance} options={{ responsive: true }} />
            </CardContent>
          </Card>
        </Grid>

        {/* Attendance Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Attendance Trend (Monthly)
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Line data={sampleData.attendanceTrend} options={{ responsive: true }} />
            </CardContent>
          </Card>
        </Grid>

        {/* Resource Usage Card */}
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Resource Usage Breakdown
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Pie data={sampleData.resourceUsage} options={{ responsive: true }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
