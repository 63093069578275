import React, { useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Divider,
  Paper,
} from "@mui/material";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [profileSettings, setProfileSettings] = useState({
    name: "",
    email: "",
    password: "",
    language: "English",
  });

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    pushNotifications: false,
    frequency: "Daily",
  });

  const [themeSettings, setThemeSettings] = useState({
    darkMode: false,
    fontSize: 14,
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSave = (section) => {
    // TODO: API Call to save section settings
    console.log(`Saving settings for ${section}`);
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "auto", p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Account Settings
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Profile Information" />
        <Tab label="Security" />
        <Tab label="Notifications & Appearance" />
        <Tab label="Integration" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {/* Profile Settings */}
        {activeTab === 0 && (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Profile Settings</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  fullWidth
                  value={profileSettings.name}
                  onChange={(e) =>
                    setProfileSettings({ ...profileSettings, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  value={profileSettings.email}
                  onChange={(e) =>
                    setProfileSettings({ ...profileSettings, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  value={profileSettings.password}
                  onChange={(e) =>
                    setProfileSettings({ ...profileSettings, password: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Language</InputLabel>
                  <Select
                    value={profileSettings.language}
                    onChange={(e) =>
                      setProfileSettings({ ...profileSettings, language: e.target.value })
                    }
                  >
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Spanish">Spanish</MenuItem>
                    <MenuItem value="French">French</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleSave("Profile")}
            >
              Save Changes
            </Button>
          </Paper>
        )}

        {/* Security Settings */}
        {activeTab === 1 && (
          <Paper sx={{ p: 2 }}>

          </Paper>
        )}

        {/* Appearance & Notification Settings */}
        {activeTab === 2 && (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Appearance Settings</Typography>
            <Typography variant="h6">Security Settings</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={notificationSettings.emailNotifications}
                  onChange={(e) =>
                    setNotificationSettings({
                      ...notificationSettings,
                      emailNotifications: e.target.checked,
                    })
                  }
                />
              }
              label="Enable Email Notifications"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={notificationSettings.pushNotifications}
                  onChange={(e) =>
                    setNotificationSettings({
                      ...notificationSettings,
                      pushNotifications: e.target.checked,
                    })
                  }
                />
              }
              label="Enable Push Notifications"
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Notification Frequency</InputLabel>
              <Select
                value={notificationSettings.frequency}
                onChange={(e) =>
                  setNotificationSettings({ ...notificationSettings, frequency: e.target.value })
                }
              >
                <MenuItem value="Immediate">Immediate</MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleSave("Notifications")}
            >
              Save Changes
            </Button>
            <br/>
            <Divider/>
            <FormControlLabel
              control={
                <Switch
                  checked={themeSettings.darkMode}
                  onChange={(e) =>
                    setThemeSettings({ ...themeSettings, darkMode: e.target.checked })
                  }
                />
              }
              label="Enable Dark Mode"
            />
            <TextField
              label="Font Size"
              type="number"
              fullWidth
              sx={{ mt: 2 }}
              value={themeSettings.fontSize}
              onChange={(e) =>
                setThemeSettings({ ...themeSettings, fontSize: parseInt(e.target.value, 10) })
              }
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleSave("Appearance")}
            >
              Save Changes
            </Button>
          </Paper>
        )}
        {/* Integration */}
        {activeTab === 3 && (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Appearance Settings</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={themeSettings.darkMode}
                  onChange={(e) =>
                    setThemeSettings({ ...themeSettings, darkMode: e.target.checked })
                  }
                />
              }
              label="Enable Dark Mode"
            />
            <TextField
              label="Font Size"
              type="number"
              fullWidth
              sx={{ mt: 2 }}
              value={themeSettings.fontSize}
              onChange={(e) =>
                setThemeSettings({ ...themeSettings, fontSize: parseInt(e.target.value, 10) })
              }
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleSave("Appearance")}
            >
              Save Changes
            </Button>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default SettingsPage;
